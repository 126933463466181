import dayjs from "dayjs";
import { isNull, isNumber } from "lodash-es";
import Vue from "vue";
import Countries from "../helpers/Countries";
import Regions from "../helpers/Regions";
import i18n from "./i18n";

Vue.filter("truncate", (value) => {
  if (!value) {
    return "";
  }
  if (value.length <= 20) {
    return value;
  }
  return `${value.substring(0, 20)}…`;
});

Vue.filter("country", (value) => {
  if (!value) {
    return "";
  }
  const country = Countries.all.find((r) => r.code === value);
  if (!country) {
    return value;
  }
  return country.name;
});

Vue.filter("region", (value) => {
  if (!value) {
    return "";
  }
  const region = Regions.all.find((r) => r.code === value);
  if (!region) {
    return value;
  }
  return region.name;
});

Vue.filter("date", (value) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = dayjs(value);
  if (!date.isValid()) {
    return value;
  }
  return date.format("DD-MM-YYYY");
});

Vue.filter("time", (value) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = dayjs(value);
  if (!date.isValid()) {
    return value;
  }
  return date.format("HH:mm");
});

Vue.filter("dateTime", (value) => {
  if (typeof value !== "string") {
    return value;
  }
  const date = dayjs(value);
  if (!date.isValid()) {
    return value;
  }
  return date.format("DD-MM-YYYY HH:mm");
});

Vue.filter("dayAndMonth", (value) => {
  if (typeof value !== "string") {
    return value;
  }
  const [monthIndex, day] = value.split("-").map((s) => parseInt(s));
  return `${i18n.t("month_" + monthIndex)} ${day}`;
});

Vue.filter("number", (value, decimals) => {
  if (isNull(value) || isNaN(value)) {
    return value;
  }
  value = parseFloat(value);
  return value.toLocaleString(undefined, {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  });
});

Vue.filter("distance", (value, systemOfMeasurement, decimals) => {
  if (isNull(value) || isNaN(value)) {
    return value;
  }
  value = parseFloat(value);
  value = value.toLocaleString(undefined, {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  });
  if (systemOfMeasurement) {
    const unit = systemOfMeasurement.toLowerCase() === "meter" ? "m" : "ft";
    value = `${value} ${unit}`;
  }
  return value;
});

Vue.filter("percentage", (value, decimals) => {
  if (isNull(value) || isNaN(value)) {
    return value;
  }
  value = parseFloat(value);
  return `${value.toLocaleString(undefined, {
    minimumFractionDigits: decimals || 0,
    maximumFractionDigits: decimals || 0,
  })} %`;
});

Vue.filter("money", (value, currency, conversionRate) => {
  if (isNull(value) || isNaN(value)) {
    return value;
  }
  value = parseFloat(value);
  if (isNumber(conversionRate)) {
    value = value * conversionRate;
  }
  switch (currency) {
    case "USD":
      currency = "$";
      break;
    case "EUR":
    default:
      currency = "€";
      break;
  }
  return `${currency} ${value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })}`;
});

Vue.filter("lc", (value) => {
  if (!value) {
    return value;
  }
  return value.toLowerCase();
});

Vue.filter("uc", (value) => {
  if (!value) {
    return value;
  }
  return value.toUpperCase();
});

Vue.filter("ucFirst", (value) => {
  if (!value) {
    return value;
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter("highestUserRole", (value) => {
  if (value.includes("ROLE_SUPERADMIN")) {
    return i18n.t("ROLE_SUPERADMIN");
  }
  if (value.includes("ROLE_USER")) {
    return i18n.t("ROLE_USER");
  }
  return value.join(", ");
});

Vue.filter("highestCompanyRole", (value) => {
  if (value.includes("ROLE_COMPANY_ADMIN")) {
    return i18n.t("ROLE_COMPANY_ADMIN");
  }
  if (value.includes("ROLE_COMPANY_USER")) {
    return i18n.t("ROLE_COMPANY_USER");
  }
  return value.join(", ");
});
